import React, { useState } from "react";
import { Link } from "react-scroll";

import logo from "../assets/white-logo-removebg.png";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    // Navbar Component
    <nav
      className={`bg-gradient-to-r from-navy to-regal-blue fixed w-full z-10 px-2 sm:px-4 cursor-pointer`}
    >
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        {/* Displaying the logo and the name */}
        <Link
          to="home"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className="flex items-center"
        >
          <img src={logo} className="h-6 mr-3 sm:h-9" alt="" />
          <span className="self-center text-white text-xl font-tag whitespace-nowrap">
            Alhikma Printing Press
          </span>
        </Link>
        {/* Rendering menu button */}
        <div
          className="text-3xl absolute right-14 top-0 cursor-pointer xl:hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <AiOutlineMenuUnfold className="text-white" />
          ) : (
            <AiOutlineClose className="text-white" />
          )}
        </div>
        <div
          className={`items-center justify-between w-full xl:flex xl:w-auto md:order-1 ${
            isOpen ? "hidden" : "left-[500px]"
          }`}
        >
          {/* Rendering the navbar list */}
          {/* Not using react-dom-router in this case */}
          <ul
            className={`flex flex-col md:p-1 md:py-2 mt-4 border border-gray-100 rounded-lg xl:flex-row xl:space-x-8 md:mt-0 md:text-sm md:font-medium xl:border-0 font-display `}
          >
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="block md:py-2 pl-3 pr-2 text-white rounded hover:text-blue-700 md:hover:bg-transparent md:p-0 sm:hover:bg-gray-100"
            >
              Home
            </Link>
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="block md:py-2 pl-3 pr-2 text-white rounded hover:text-blue-700 md:hover:bg-transparent md:p-0 sm:hover:bg-gray-100"
            >
              About
            </Link>
            <Link
              to="services"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="block md:py-2 pl-3 pr-2 text-white rounded hover:text-blue-700 md:hover:bg-transparent md:p-0 sm:hover:bg-gray-100"
            >
              Services
            </Link>
            <Link
              to="projects"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="block md:py-2 pl-3 pr-2 text-white rounded hover:text-blue-700 md:hover:bg-transparent md:p-0 sm:hover:bg-gray-100"
            >
              Projects
            </Link>
            <Link
              to="contacts"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className="block md:py-2 pl-3 pr-2 text-white rounded hover:text-blue-700 md:hover:bg-transparent md:p-0 sm:hover:bg-gray-100"
            >
              Contact
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
