import React, { useState } from "react";

import Banner from "../assets/banner.jpg";
import "../CSS/Styles.css";
import StairSide from "../assets/StairSidecopy.png";
import { AiOutlineRight } from "react-icons/ai";
import { AiOutlineLeft } from "react-icons/ai";

const slides = [
  {
    url: Banner,
  },
  {
    url: StairSide,
  },
  {
    url: Banner,
  },

  {
    url: StairSide,
  },
  {
    url: Banner,
  },
  {
    url: StairSide,
  },
];

const Advertise = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  // Weekly advertisement Component
  // This can be videos or Images
  return (
    <>
      <div className="max-w-lg mx-auto text-center">
        <h2 className="text-2xl md:text-2xl font-display font-heading text-gray-500">
          Trending now
        </h2>
      </div>
      <div className="max-w-[1400px] h-[780px] w-full m-auto py-16 px-4 relative group weekly-bg">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
          className="weekly-image bg-cover duration-500 "
        ></div>

        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <AiOutlineLeft onClick={prevSlide} size={30} />
        </div>
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <AiOutlineRight onClick={nextSlide} size={30} />
        </div>
      </div>
    </>
  );
};

export default Advertise;

// Jakes
