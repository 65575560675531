import React from "react";

import picture from "../assets/WhatsApp Image 2023-01-24 at 9.57.22 PM.jpeg";
import picture1 from "../assets/WhatsApp Image 2023-01-24 at 9.56.35 PM.jpeg";
import picture2 from "../assets/WhatsApp Image 2023-01-24 at 9.56.36 PM.jpeg";
import picture3 from "../assets/WhatsApp Image 2023-01-24 at 9.56.27 PM.jpeg";
import picture4 from "../assets/WhatsApp Image 2023-01-24 at 9.56.34 PM.jpeg";
import picture5 from "../assets/WhatsApp Image 2023-01-24 at 9.57.30 PM.jpeg";

const Projects = () => {
  // Projects component
  // This component showcases some of the projects done by Alhikma
  return (
    <section className="relative py-20 2xl:py-40 overflow-hidden" id="projects">
      <div className="container px-4 mx-auto mt-10">
        <div className="max-w-lg mx-auto text-center">
          <h2 className="text-2xl md:text-2xl font-display font-heading text-gray-500">
            Works Done
          </h2>
        </div>
        <div>
          <div className="flex flex-wrap mb-6 cursor-pointer sm:mt-10">
            <div className="w-full md:w-1/2 lg:w-1/2 px-3 mb-6">
              <div className="p-1 bg-white shadow rounded">
                <img src={picture} alt="" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/2 px-3 mb-6">
              <div className="p-1 bg-white shadow rounded">
                <img src={picture1} alt="" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/2 px-3 mb-6">
              <div className="p-1 bg-white shadow rounded">
                <img src={picture2} alt="" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/2 px-3 mb-6">
              <div className="p-1 bg-white shadow rounded">
                <img src={picture3} alt="" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/2 px-3 mb-6">
              <div className="p-1 bg-white shadow rounded">
                <img src={picture4} alt="" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/2 px-3 mb-6">
              <div className="p-1 bg-white shadow rounded">
                <img src={picture5} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
