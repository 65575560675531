import React from "react";

// Importing Icons from react-icons
import { IoDiamondOutline } from "react-icons/io5";
import { BiImages } from "react-icons/bi";
import { BsHandThumbsUpFill } from "react-icons/bs";
import { BsFiles } from "react-icons/bs";
import { FaRegHandPointRight } from "react-icons/fa";
import graphic from "../assets/WhatsApp Image 2023-01-24 at 9.56.37 PM.jpeg";
import branding from "../assets/WhatsApp Image 2023-01-24 at 9.57.22 PM.jpeg";
import printing from "../assets/WhatsApp Image 2023-01-24 at 9.56.38 PM.jpeg";
import editing from "../assets/sanjeev-nagaraj-u4bvBOOpZB4-unsplash.jpg";
import "../CSS/Styles.css";

// Background styling
// const myStyle = {
//   clipPath: "polygon(0px 13%, 100% 0px, 100% 100%, 0px 96%)",
// };

const Features = () => {
  // Features component && Services compnent
  return (
    <section className="relative overflow-hidden" id="features">
      <div className="bg-gradient-to-r from-navy to-regal-blue">
        <div className="py-20 px-42 z-50 bFeatures-bg">
          <div className="container mx-auto flex flex-wrap">
            <div className="w-full text-center md:w-1/2 lg:w-1/2 xl:w-1/4 lg:px-8 mb-20 lg:mb-0">
              <div className="flex mb-2 justify-center items-center w-20 h-20 rounded mx-auto">
                <IoDiamondOutline className="w-16 h-16 text-gray-100" />
              </div>
              <h2 className="mt-1 font-heading font-body text-2xl text-gray-100">
                High Quality
              </h2>
              <p className=" text-sm text-white font-body">
                Quality, service, and satisfaction are everything to us!
              </p>
            </div>

            <div className="w-full text-center md:w-1/2 lg:w-1/2 xl:w-1/4 lg:px-8 mb-20 lg:mb-0">
              <div className="flex mb-2 justify-center items-center w-20 h-20 rounded mx-auto">
                <BiImages className="w-16 h-16 text-gray-100" />
              </div>
              <h2 className="mt-1 font-heading text-white font-body text-2xl">
                Variety Sizes
              </h2>
              <p className="text-sm text-white font-body">
                Designs for every shape and sizes
              </p>
            </div>

            <div className="w-full text-center md:w-1/2 lg:w-1/2 xl:w-1/4 lg:px-8 mb-20 lg:mb-0 xl:mt-0 lg:mt-5">
              <div className="flex mb-2 justify-center items-center w-20 h-20 rounded mx-auto">
                <BsHandThumbsUpFill className="w-16 h-16 text-gray-100" />
              </div>
              <h2 className="mt-1 font-heading text-white font-body text-2xl">
                Trusted Services
              </h2>
              <p className="text-sm text-white font-body">
                We give the Best. We Receive the best.
              </p>
            </div>

            <div className="w-full text-center md:w-1/2 lg:w-1/2 xl:w-1/4 lg:px-8 mb-20 lg:mb-0 xl:mt-0 lg:mt-5">
              <div className="flex mb-2 justify-center items-center w-20 h-20 rounded mx-auto">
                <BsFiles className="w-16 h-16 text-gray-100" />
              </div>
              <h2 className="mt-1 font-heading text-white font-body text-2xl">
                Free Cutting
              </h2>
              <p className="text-sm text-white font-body">
                Try not to waste it anywhere, save paper.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Services Offered by Alhikma */}
      <div className="container px-4 mx-auto" id="services">
        <div className="xl:columns-2 2xl:ml-36 sm:ml-0 gap-36 m-auto sm:mt-10 cursor-pointer mt-5 mb-5">
          <img src={graphic} alt="" className="rounded-2xl" />
          <h1 className="text-2xl flex sm:justify-center font-body underline mb-3">
            Graphic Design
          </h1>
          <div className="flex space-x-4">
            <FaRegHandPointRight className="text-black font-body text-4xl" />
            <div className="text-black font-body">
              <span className="font-bold"> Logo Design: </span> A custom logo
              design is essential to help convey your company's brand and
              identity. A professional designer can help create a logo that is
              unique and memorable.
            </div>
          </div>{" "}
          <div className="flex space-x-4">
            <FaRegHandPointRight className="text-black font-body text-4xl" />
            <div className="text-black font-body">
              <span className="font-bold">Business Card Design: </span> A good
              business card design can help make a lasting impression on
              potential clients. A professional designer can help create a card
              that stands out.
            </div>
          </div>{" "}
          <div className="flex space-x-4">
            <FaRegHandPointRight className="text-black font-body text-4xl" />
            <div className="text-black font-body">
              <span className="font-bold">Website Design: </span>A website is a
              powerful tool to reach a larger audience. A professional designer
              can help create a website that is user-friendly and visually
              appealing.
            </div>
          </div>{" "}
          <div className="flex space-x-4">
            <FaRegHandPointRight className="text-black font-body text-4xl" />
            <div className="text-black font-body">
              <span className="font-bold">Brochure Design:</span> A brochure is
              a great way to share information about your company. A
              professional designer can help create a brochure that is
              professional and informative.
            </div>
          </div>{" "}
          <div className="flex space-x-4">
            <FaRegHandPointRight className="text-black font-body text-4xl" />
            <div className="text-black font-body">
              <span className="font-bold">Infographic Design: </span>
              Infographics are a great way to communicate complex information in
              a visually appealing format. A professional designer can help
              create an infographic that is both engaging and informative
            </div>
          </div>{" "}
        </div>

        <div className="xl:columns-2 2xl:ml-36 sm:ml-0 gap-36 m-auto sm:mt-10 cursor-pointer mt-5 mb-5">
          <h1 className="text-2xl flex sm:justify-center font-body underline mb-3">
            Branding
          </h1>
          <p className="font-body">
            Brand recognition and building a strong identity are key components
            to the success of any business. We can help create a brand identity
            that is unique and stands out from the competition. We will work
            with you to develop a logo, color scheme, and other visual elements
            that will help your brand stand out.
          </p>
          <img src={branding} alt="" className="rounded-2xl" />
        </div>

        <div className="xl:columns-2 2xl:ml-36 sm:ml-0 gap-36 m-auto sm:mt-10 cursor-pointer mt-5 mb-5">
          <img src={editing} alt="" className="rounded-2xl" />
          <h1 className="text-2xl flex sm:justify-center underline mb-3 font-body">
            Video Editing
          </h1>
          <p className="font-body">
            We do offer video editing services. Our experienced team of editors
            can help you with any project, from creating a simple video montage
            to more complex tasks like creating a full-length movie. We can help
            you with all types of video editing, from basic cuts to color
            correction and sound mixing. We also offer a variety of
            post-production services, such as adding special effects and
            creating custom titles.
          </p>
        </div>

        <div className="xl:columns-2 2xl:ml-36 sm:ml-0 gap-36 m-auto sm:mt-10 cursor-pointer mt-5 mb-5">
          <h1 className="text-2xl flex sm:justify-center underline mb-3 font-body">
            Printing
          </h1>
          <p className="font-body">
            We print books, flyers, brochures and all forms of print media. We
            have a wide range of printing services that can meet your needs.
            From full-color offset printing to digital printing, we have the
            right solution for you. We also offer finishing services, like
            binding and laminating, to help you get the most out of your
            printing project.
          </p>
          <img src={printing} alt="" className="rounded-2xl" />
        </div>
      </div>
    </section>
  );
};

export default Features;
