import React, { useRef } from "react";

//Importing Emailjs for easy use to recieve emails from the website
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  // Sending email function
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_sr53fg5",
        "template_5o94yip",
        form.current,
        "c5X5C8XlUKOh4lDM-"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // Contact component
  return (
    <section className="relative py-20 2xl:py-20 overflow-hidden" id="contacts">
      <div className="container mx-auto">
        <div className="max-w-lg mx-auto mb-12 text-center">
          <h2 className="mb-5 text-2xl md:text-2xl md:mt-5 font-display font-heading text-gray-500">
            Contact us
          </h2>
        </div>
        <div className="grid mb-8 rounded-lg drop-shadow  md:mb-12 2xl:grid-cols-2">
          {/* Contact form */}
          <figure className="flex flex-col md:p-8 md:mb-0 sm:mb-5 bg-white rounded-t-lg md:rounded-t-none md:border-r p-2">
            <p className="text-lg text-gray-500 font-display mb-5">
              We will respond as soon as possible
            </p>
            <form ref={form} onSubmit={sendEmail}>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="user_name"
                  id="floating_first_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-regal-blue appearance-none
                     focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="name"
                  className="peer-focus:font-medium absolute text-sm text-navy duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] 
                    peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Name
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="email"
                  name="user_email"
                  id="floating_company"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-regal-blue appearance-none
                     focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_company"
                  className="peer-focus:font-medium absolute text-sm text-navy duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] 
                    peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email address
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <textarea
                  rows="5"
                  type="tel"
                  name="message"
                  id="floating_phone"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent rounded border-2 border-regal-blue appearance-none
                     focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                ></textarea>
                <label
                  htmlFor="floating_phone"
                  className="peer-focus:font-medium absolute left-2 text-sm text-navy duration-300 transform -translate-y-2 scale-75 top-3 -z-10 origin-[0] 
                    peer-focus:left-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3"
                >
                  Your message
                </label>
              </div>
              <button
                type="submit"
                className="text-white bg-blue-400 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg 
                text-sm w-full px-5 py-2.5 text-center"
              >
                Submit
              </button>
            </form>
          </figure>

          <figure className="flex flex-col p-8 bg-gradient-to-r from-navy to-regal-blue border-b 2xl:rounded-tr-lg contact-card">
            {/* Alhikma Details Section */}
            <p className="text-lg text-white font-display mb-5">Reach us</p>
            <div className="flex space-x-4 md:ml-40">
              <div className="text-white font-display text-xl">Email</div>
              <div className="text-white font-body">
                info@alhikmaprinting.co.ke
              </div>
            </div>{" "}
            <br />
            <div className="flex space-x-4 md:ml-40">
              <div className="text-white font-display text-xl">Phone</div>
              <div className="text-white font-body">+254 712 881 440</div>
            </div>{" "}
            <br />
            <div className="flex space-x-4 md:ml-40">
              <div className="text-white font-display text-xl">Address</div>
              <div className="text-white font-body">
                Dhasheeg Towers
                <br /> Sixth Street, Second Avenue, <br />
                Eastleigh Nairobi Kenya, <br /> Nairobi 2664-00610,
              </div>
            </div>
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Contact;
