import React from "react";

import picture from "../assets/alhikmq-3d-logo.png";
import "../CSS/Styles.css";

const About = () => {
  // About Component
  return (
    <section id="about">
      {/* About the Alhikma */}
      <div className="bg-gradient-to-r from-navy to-regal-blue about-bg sm:mt-5">
        <div className="max-w-lg mx-auto text-center">
          <h2 className="text-2xl md:text-2xl font-heading font-display text-black">
            About Us
          </h2>
        </div>
        <div className="container xl:columns-2 2xl:ml-36 sm:ml-0 gap-36 m-auto card">
          <div className="text-xl text-gray-500 p-8 bg-white shadow rounded flex">
            <p className="font-body">
              At Alhikma we strive to provide you with innovative communication
              through carefully considered design and branding strategies. Our
              objective is to help you take the next big step in expanding your
              business and successfully connecting with your audience. We tell
              tales as well as create designs. <br />
              Our mission is to be the top graphic design firm, offering unique
              and imaginative solutions that excite and motivate our customers.
              We strive to foster an atmosphere of collaboration between our
              team and our clients, and to ensure that our processes are
              efficient and effective. <br />
              To offer top-notch design services that will help you stay
              competitive.
            </p>
          </div>
          {/* An image of Alhikma */}
          <img src={picture} alt="" width="300rem" className="mt-10" />
        </div>
      </div>
    </section>
  );
};

export default About;
