import React from "react";

const Testimonials = () => {
  // Testimonials component
  // These are some of the testimonials by Alhikma clients
  return (
    <section className="relative py-20 2xl:py-20 overflow-hidden">
      <div className="container px-4 mx-auto">
        <div className="md:max-w-lg mx-auto mb-12 text-center">
          <svg
            className="mx-auto mb-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 
                1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 
                0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 
                6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 
                3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
              fill="rgba(41,114,243,1)"
            />
          </svg>
          <h2 className="mb-5 text-2xl md:text-2xl font-display font-heading text-gray-500">
            What our clients are saying about us
          </h2>
          <p className="text-gray-400 font-body">
            With regards to our clients we are consistently anxious to fulfill
            their plans and marking needs to live up to their assumptions, here
            are what they are saying about us
          </p>
        </div>

        <div className="flex flex-wrap mb-6 cursor-pointer">
          <div className="w-full md:w-1/2 2xl:w-1/3 lg:w-1/2 px-3 mb-6">
            <div className="p-8 bg-white shadow rounded">
              <div className="flex items-center mb-4">
                <div className="pl-4">
                  <p className="text-lg text-gray-500 font-display">
                    Mohammed Osman
                  </p>
                  <p className="text-blue-500 font-display">
                    Islamic School Director
                  </p>
                </div>
              </div>
              <p className="text-sm text-gray-400 font-body">
                We are extremely satisfied with the products and services we
                receive from Alhikma Printing Press. The staff is very customer
                oriented and a pleasure to do business with. Our orders are
                delivered error-free in a timely, consistent manner. We
                appreciate your dedication to your customers.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 2xl:w-1/3 lg:w-1/2 px-3 mb-6">
            <div className="p-8 bg-white shadow rounded">
              <div className="flex items-center mb-4">
                <div className="pl-4">
                  <p className="text-lg text-gray-500 font-display">
                    Abdikadir Hassan
                  </p>
                  <p className="text-blue-500 font-display">
                    Real Estate Developer
                  </p>
                </div>
              </div>
              <p className="text-sm text-gray-400 font-body">
                Reasons why I like doing business with Alhikma Printing Press:
                Friendly, helpful staff. Great ideas about what we should use in
                our business marketing. Easy to work with - I can send a picture
                of what someone else previously created, and Alhikma can make it
                better every time. Quick turnaround. Quality products at a
                reasonable price.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 2xl:w-1/3 lg:w-1/2 px-3 mb-6">
            <div className="p-8 bg-white shadow rounded">
              <div className="flex items-center mb-4">
                <div className="pl-4">
                  <p className="text-lg text-gray-500 font-display">
                    Abshir Leban
                  </p>
                  <p className="text-blue-500 font-display">
                    Ceremony Organiser
                  </p>
                </div>
              </div>
              <p className="text-sm text-gray-400 font-body">
                Just wanted to get back to you and let you know how grateful I
                am to have a resource like Alhikma Printing Press. You guys
                literally perform miracles. I had reached out to 5 other
                printing businesses while we were waiting on confirmation from
                you about being able to handle the job, and all of them turned
                it down due to either not having enough materials or because
                they said it was physically impossible for a large format
                printer to accomplish in such a short time. Alhikma Printing
                Press continues to raise the bar with every new challenge.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 2xl:w-1/3 lg:w-1/2 px-3 mb-6">
            <div className="p-8 bg-white shadow rounded">
              <div className="flex items-center mb-4">
                <div className="pl-4">
                  <p className="text-lg text-gray-500 font-display">
                    Hamza Abdalla
                  </p>
                  <p className="text-blue-500 font-display">Trip Organiser</p>
                </div>
              </div>
              <p className="text-sm text-gray-400 font-body">
                These guys do a GREAT job printing just about anything you could
                ever need. I use Alhikma Printing Press exclusively for all of
                my business printing. They make posters, stickers, even gigantic
                banners. The turnaround time is always fast, and the prices are
                excellent. In a world where printing can be seen as a commodity,
                Alhikma Printing Press still takes the time and pride in their
                work to do a careful and excellent job on everything they print.
                Highly recommended
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 2xl:w-1/3 lg:w-1/2 px-3 mb-6">
            <div className="p-8 bg-white shadow rounded">
              <div className="flex items-center mb-4">
                <div className="pl-4">
                  <p className="text-lg text-gray-500 font-display">
                    Ahmed Ali
                  </p>
                  <p className="text-blue-500 font-display">
                    IT Solutions Shop Owner
                  </p>
                </div>
              </div>
              <p className="text-sm text-gray-400 font-body">
                In most cases business offer 2 of 3 things; speed, quality, and
                price. Alhikma Printing Press offers all three of these things
                to their clients. It is an amazingly easy process to have your
                business cards, brochures and posters printed there. If you have
                not yet tried Alhikma Printing Press it is one of the easiest
                ways to submit your artwork without ever having to pick up the
                phone. Thanks for being such a great business and creating such
                a great product.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 2xl:w-1/3 lg:w-1/2 px-3 mb-6">
            <div className="p-8 bg-white shadow rounded">
              <div className="flex items-center mb-4">
                <div className="pl-4">
                  <p className="text-lg text-gray-500 font-display">
                    Omar Abdullahi
                  </p>
                  <p className="text-blue-500">Travel Agency Owner</p>
                </div>
              </div>
              <p className="text-sm text-gray-400 font-body">
                The business cards, and folders printed at Alhikma Printing
                Press were excellent, delivered quickly and efficiently. Alhikma
                provided a perfect solution to our needs, and throughout were a
                pleasure to work with. You took the time to understand my
                business, future growth plans, and market place. Culminating in
                a perfect solution to present my business, reports and design
                work. All of the work provided was completed to a high standard.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
